import React from 'react'
import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import LoginForm from './LoginForm'

const NavigationBar = ({
	user, username, password, isPicking, 
	handleLogin, handleLogout, cancelPicking, startPicking, updatePoints,
	setUsername, setPassword
}) => {
	return (
		<Navbar expand='lg' bg='dark' variant='dark'>
			<Navbar.Brand>Bowl Game Game</Navbar.Brand>
			<Navbar.Toggle aria-controls='responsive-navbar-nav' />
  			<Navbar.Collapse id='responsive-navbar-nav' className='justify-content-end'>
				{
					user === null ? (
						<LoginForm 
							username={username}
							password={password}
							handleUsernameChange={({ target }) => setUsername(target.value)}
							handlePasswordChange={({ target }) => setPassword(target.value)}
							handleLogin={handleLogin}
						/>
					)
					: (<>
						{
							isPicking ?
								<Button id='cancel-picks-btn' variant='outline-light' onClick={cancelPicking}>Cancel</Button> :
								<Button id='set-picks-btn' variant='outline-light' onClick={startPicking}>Set Picks</Button>
						}
						{
							user.username === 'michael.onjack' ?
							<Button id='update-points-btn' variant='outline-light' onClick={updatePoints}>Update Points</Button> :
							null
						}
						<Button variant='outline-light' onClick={handleLogout}>Logout</Button>
					</>)
				}
			</Navbar.Collapse>
		</Navbar>
	)
}

export default NavigationBar