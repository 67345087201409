import React, {useState, useEffect} from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import Leaderboard from './components/Leaderboard'
import NavigationBar from './components/NavigationBar'
import Schedule from './components/Schedule'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import gameService from './services/games'
import loginService from './services/login'
import pickService from './services/picks'
import pointsService from './services/points'
import userService from './services/users'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'

smoothscroll.polyfill();

const App = () => {
	const [games, setGames] = useState([])
	const [users, setUsers] = useState([])
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [isPicking, setIsPicking] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [user, setUser] = useState(null)
	const [errors, setErrors] = useState([])

	// Reload any previous user sessions
	useEffect(() => {
		const loggedInUserJSON = window.localStorage.getItem('appUser')
		if(loggedInUserJSON) {
			const loggedInUser = JSON.parse(loggedInUserJSON)
			setUser(loggedInUser)
			pickService.setToken(loggedInUser.token)

			// Reload the user in case they made updates from a different device
			async function loadUser() {
				const updatedUser = await userService.get(loggedInUser.id)
				updatedUser.token = loggedInUser.token
				setUser(updatedUser)
			}
			loadUser()
		}
	}, [])

	// Load the games
	useEffect(() => {
		gameService.getAll().then(initialGames => {
			setGames(initialGames.sort((a,b) => (a.date > b.date) ? 1 : -1))
		})

		userService.getAll().then(initialUsers => {
			setUsers(initialUsers.sort((a, b) => (a.name > b.name) ? 1 : -1))
		})
	}, [])

	// Load the current user's previous picks
	useEffect(() => {
		if(isPicking && user && user.picks && user.picks.length > 0 && errors.length === 0) {
			user.picks.forEach(pick => {
				document.getElementsByName('points_' + pick.game.id)[0].value = pick.points
				document.getElementById(pick.game.id + '_' + pick.team.id).checked = true
			})
		}
	})

	const handleLogin = async (event) => {
		event.preventDefault()

		try {
			const u = await loginService.login({ username: username.toLowerCase().trim(), password })

			window.localStorage.setItem('appUser', JSON.stringify(u))
			
			pickService.setToken(u.token)
			setUser(u)
			setUsername('')
			setPassword('')
		} catch(exception) {
			console.log(exception)
		}
	}

	const handleLogout = async (event) => {
		event.preventDefault()

		window.localStorage.removeItem('appUser')
		setUser(null)
		setIsPicking(false)
	}

	const startPicking = (event) => {
		Array.from(document.getElementsByClassName("collapsible-active")).forEach(
			function(element) {
				element.click()
			}
		)

		setIsPicking(true)
	}

	const cancelPicking = (event) => {
		setIsPicking(false)
		setErrors([])
	}

	const updatePoints = (event) => {
		pointsService.updatePoints()
	}

	const validatePicks = (event) => {
		let errorMessages = []
		let pointValuesUsed = {}
		let availableGames = games.filter(game => game.awayTeam.teamName !== 'TBD' && game.homeTeam.teamName !== 'TBD')

		availableGames.forEach(game => {
			// Determine if a valid number was entered into the points input
			const points = document.querySelector('input[name="points_' + game.id + '"]').value
			if(points === '') {
				return;
			} else if(isNaN(points)) {
				errorMessages.push(game.awayTeam.teamName + ' vs. ' + game.homeTeam.teamName + ' - Points must be a valid number')
			} else if(+points < 1 || +points > games.length) {
				errorMessages.push(game.awayTeam.teamName + ' vs. ' + game.homeTeam.teamName + ' - Points must be between 1 and 44')
			}
			
			// Determine if the point value has already been used
			if(pointValuesUsed[points]) {
				errorMessages.push(game.awayTeam.teamName + ' vs. ' + game.homeTeam.teamName + ' - You have already used the point value ' + points + '. Each point value can only be used once.')
			}

			// Determine if a team was selected
			const team = document.querySelector('input[name="pick_' + game.id + '"]:checked')
			if(!team || !team.value) {
				errorMessages.push(game.awayTeam.teamName + ' vs. ' + game.homeTeam.teamName + ' - You must select a team')
			}

			pointValuesUsed[points] = true
		})

		return errorMessages
	}

	const submitPicks = async (event) => {
		setIsSubmitting(true)
		
		const errorMessages = validatePicks(event)

		if(errorMessages.length > 0) {
			setIsSubmitting(false)
			setErrors(errorMessages)
			window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
		} else {
			let availableGames = games.filter(game => game.awayTeam.teamName !== 'TBD' && game.homeTeam.teamName !== 'TBD')

			let picks = availableGames.map(game => {
				if(document.querySelector('input[name="pick_' + game.id + '"]:checked') == null) return null;

				const points = document.querySelector('input[name="points_' + game.id + '"]').value
				const team = document.querySelector('input[name="pick_' + game.id + '"]:checked').value

				if(points === '') return null;

				return {
					'game': game.id,
					team,
					points
				}
			}).filter(pick => pick != null)

			let updatedUser = await pickService.create(picks)
			updatedUser.token = user.token
			window.localStorage.setItem('appUser', JSON.stringify(updatedUser))
			setIsSubmitting(false)
			setIsPicking(false)
			setErrors([])
			setUser(updatedUser)
			setUsers(users.map(u => u.id !== updatedUser.id ? u : updatedUser))
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		}
	}

	const randomizePoints = (event) => {
		let allPointValues = []
		for(let i = 1; i <= games.length; i++) {
			allPointValues.push(i.toString())
		}

		let availableGames = games.filter(game => game.awayTeam.teamName !== 'TBD' && game.homeTeam.teamName !== 'TBD')
		let usedPointValues = availableGames.map(game => {
			return document.querySelector('input[name="points_' + game.id + '"]').value
		}).filter(points => points != null && points !== '')

		let unusedPointsValues = allPointValues.filter(x => !usedPointValues.includes(x))
		let shuffledUnusedPointValues = unusedPointsValues.sort((a, b) => 0.5 - Math.random())

		let i = 0;
		availableGames.forEach((game) => {
			let points = document.querySelector('input[name="points_' + game.id + '"]').value
			let isDisabled = document.querySelector('input[name="points_' + game.id + '"]').hasAttribute("disabled")

			if(!isDisabled && (points == null || points === '')) {
				document.querySelector('input[name="points_' + game.id + '"]').value = shuffledUnusedPointValues[i]
				i = i + 1
			}
		})
	}
	
	return (
		<div>
			<NavigationBar user={user} username={username} password={password} 
				isPicking={isPicking} handleLogin={handleLogin} handleLogout={handleLogout}
				cancelPicking={cancelPicking} startPicking={startPicking} updatePoints={updatePoints}
				setUsername={setUsername} setPassword={setPassword}
			/>
			{
				isPicking ? (
					<>
					<Container className='instructions-container' fluid>
						<Alert variant='info'>
							<h3>Instructions:</h3>
							<p>For each game, pick a winner and assign a number of points to bet on your pick. You can bet between 1 and {games.length} points on each game but each point value can ONLY BE USED ONCE. In other words, you cannot bet 32 points on Game A and 32 points again on Game B.</p>
							<p>If your pick wins, then you earn the points that you bet on that game. If your pick loses then you get 0 points. You can update each pick any time before the start of the game.</p>
							<p>You can use the "Randomize My Points" button to automatically assign a random number of points to every game that does not currently have a point value.</p>
						</Alert>
					</Container>
					<Container className='randomize-points-btn-container' fluid>
						<Row>
							<Col>
								<Button id='randomize-points-btn' 
									variant='primary'
									onClick={randomizePoints}>
									Randomize My Points
								</Button>
							</Col>
						</Row>
					</Container>
					</>
				) : null
			}
			<Leaderboard users={users} />
			<Schedule users={users} games={games} isPicking={isPicking} />
		
			{
				isPicking ?
				<Container className='submit-picks-btn-container' fluid>
					<Row>
						<Col>
							<Button id='submit-picks-btn' 
								variant='primary'
								disabled={isSubmitting}
								onClick={isSubmitting ? null : submitPicks}>
								{isSubmitting ? 'Submitting...' : 'Submit Picks'}
							</Button>
						</Col>
					</Row>
				</Container>
				: null
			}
			{
				errors.length > 0 ?
				<Container className='error-messages-container' fluid>
					<Alert variant='danger'>
					<ul>{errors.map((error, i) => <li key={i}>{error}</li>)}</ul>
					</Alert>
				</Container>
				: null
			}
		</div>
	)
}

export default App