import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const toggleCollapse = (event) => {
	event.currentTarget.classList.toggle('collapsible-active')
	
	let content = event.currentTarget.nextElementSibling
	if(content.style.maxHeight) {
		content.style.maxHeight = null
	} else {
		content.style.maxHeight = content.scrollHeight + "px"
	}
}

const Collapsible = (props) => {
	return (
		<Row className='collapsible-container'>
			<Col>
				{
					props.header ? 
					<Row className="collapsible-header">
						<Col>
							<h4>{props.header}</h4>
						</Col>
					</Row> : null
				}
				<Row>
					<Col>
						<button className='collapsible' onClick={props.collapseEnabled ? toggleCollapse : null}>
							{props.defaultContent}
						</button>
						<div className='collapsible-content'>
							{props.children}
						</div>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default Collapsible