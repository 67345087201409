import React from 'react'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'

const LeaderboardRow = ({user}) => {
	return (
		<ListGroup.Item>
			<Row className='align-items-center'>
				<Col xs={4} md={4}>{user.name}</Col>
				<Col xs={4} md={4}>{user.points}</Col>
			</Row>
		</ListGroup.Item>
	)
}

export default LeaderboardRow