import React from 'react'
import moment from 'moment'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import Collapsible from './Collapsible'
import GameRow from './GameRow'
import Pick from './Pick'

const Schedule = ({users, games, isPicking}) => {
	return (
		<Container fluid>
			{
				games.map((game, i) => {
					let newHeader = true
					if(i !== 0) {
						const lastGameDate = games[i-1].date.toString().slice(0,10)
						const currentGameDate = game.date.toString().slice(0, 10)
						newHeader = currentGameDate !== lastGameDate
					}

					const gameDate = moment(game.date).format('dddd, MMMM Do')
					
					return <Collapsible 
						key={i}
						collapseEnabled={!isPicking}
						defaultContent={
							<GameRow isPicking={isPicking} 
								game={game}
							/>
						}
						header={newHeader ? gameDate.toUpperCase() : null}>
							<ListGroup variant="flush">
							{
								users.map((user, j) => <Pick key={j} game={game} user={user} /> )
							}
							</ListGroup>
					</Collapsible>
				})
			}
		</Container>
	)
}

export default Schedule