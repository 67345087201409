import React from 'react'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const GameRow = ({isPicking, game}) => {
	return (
		<Row className='game-row-container'>
			<Col>
				<Row>
					<Col className='game-row-header'>
						{game.name}
					</Col>
				</Row>
				<Row id={'game_' + game.id} className='game-row align-items-center'>
					<Col md={1} className='game-row-time d-none d-md-block'>
						{
							game.winner ? 'FINAL' :
							moment(game.date).utc().format('hh:mm A')
						}
					</Col>
					<Col xs={2} md={2} className='game-row-team' style={{textAlign: 'right'}}>
						{game.awayTeam.teamName}<br />{' (' + game.awayTeam.wins + '-' + game.awayTeam.losses + ')' }
					</Col>
					<Col xs={2} sm={2} md={isPicking ? 1 : 2} lg={2} className='game-row-team-logo'>
						<img id={'team_' + game.awayTeam.id}
							alt='away team logo' 
							src={game.awayTeam.logoUrl} 
						/>
					</Col>
					{
						isPicking && game.awayTeam.teamName !== 'TBD' && game.homeTeam.teamName !== 'TBD' ?
						<Col xs={4} md={4} lg={2} className='game-row-points'>
							<div className="input-group">
  								<div className="input-group-prepend">
    								<div className="input-group-text pick-radio-parent">
										<input type="radio" 
											aria-label="Radio button for following text input"
											id={game.id + '_' + game.awayTeam.id}
											name={'pick_' + game.id} 
											value={game.awayTeam.id}
											disabled={(new Date() > moment(game.date).add(5, 'hours').toDate())} 
										/>
    								</div>
  								</div>
								<input type="text" name={'points_' + game.id} placeholder='Points'
										inputMode='numeric'
										pattern='[0-9]*'
										maxLength='2'
										disabled={(new Date() > moment(game.date).add(5, 'hours').toDate())}
									  	className="form-control" 
									  	aria-label="Text input with radio button" 
								/>
								<div className="input-group-append">
    								<div className="input-group-text pick-radio-parent">
										<input type="radio" 
											id={game.id + '_' + game.homeTeam.id} 
											name={'pick_' + game.id} 
											value={game.homeTeam.id}
											disabled={(new Date() > moment(game.date).add(5, 'hours').toDate())}
											aria-label="Radio button for following text input" 
										/>
    								</div>
  								</div>
							</div>
						</Col>
						:
						<Col xs={4} md={2} className='game-row-score' style={{textAlign: 'center'}}>
							{(game.awayTeamScore ?? '0')}
							<span style={{margin: '0em 1em 0em 1em'}}>-</span>
							{(game.homeTeamScore ?? '0')} 
						</Col>
					}
					<Col xs={2} sm={2} md={isPicking ? 1 : 2} lg={2} className='game-row-team-logo'>
						<img id={'team_' + game.homeTeam.id}
							alt='home team logo' 
							src={game.homeTeam.logoUrl} 
						/>
					</Col>
					<Col xs={2} md={2} className='game-row-team' style={{textAlign: 'left'}}>
						{game.homeTeam.teamName}<br />{' (' + game.homeTeam.wins + '-' + game.homeTeam.losses + ')'}
					</Col>
					<Col md={1} className='game-row-channel d-none d-md-block'>
						{game.channel}
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default GameRow