import axios from 'axios'

const baseUrl = '/api/users'

const get = async (id) => {
	const response = await axios.get(baseUrl + '/' + id)
	return response.data
}

const getAll = async () => {
	const response = await axios.get(baseUrl)
	return response.data
}

export default {
	get, getAll
}