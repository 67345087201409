import React from 'react'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'

const Pick = ({ game, user }) => {
	const pick = user.picks.find(p => p.game.id === game.id)

	if(pick) {
		const pickClass = game.winner ? 
			(game.winner === pick.team.id ? 'pick-winner' : 'pick-loser') 
			: ''

		return (
			<ListGroup.Item className={pickClass}>
				<Row className="align-items-center">
					<Col xs={5} md={4}>{user.name}</Col>
					<Col xs={5} md={4}>{pick.team.teamName}</Col>
					<Col xs={2} md={4}>{pick.points}</Col>
				</Row>
			</ListGroup.Item>
		)
	} else {
		return null
	}
	
}

export default Pick