import React from 'react'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import Collapsible from './Collapsible'
import LeaderboardRow from './LeaderboardRow'

const Leaderboard = ({users}) => {
	return (
		<Container fluid>
			<Collapsible 
				collapseEnabled={true}
				defaultContent={
					<div className='leaderboard-header'><b>LEADERBOARD</b></div>
				}
				header={null}>
					<ListGroup variant="flush">
					{
						[...users]
							.sort((a,b) => (a.points < b.points) ? 1 : -1)
							.map((user, i) => <LeaderboardRow key={i} user={user} /> )
					}
					</ListGroup>
			</Collapsible>
		</Container>
	)
}

export default Leaderboard