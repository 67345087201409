import axios from 'axios'

const baseUrl = '/api/picks'

let token = null

const setToken = (newToken) => {
	token = `bearer ${newToken}`
}

const create = async (picks) => {
	const config = {
		headers: { Authorization: token }
	}

	const response = await axios.post(baseUrl, {'picks': picks}, config)
	return response.data
}

export default { setToken, create }