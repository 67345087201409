import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

const LoginForm = ({
	username,
	password,
	handleUsernameChange,
	handlePasswordChange,
	handleLogin
}) => {
	return (
		<Form className='login-form' inline onSubmit={handleLogin}>
			<Form.Label htmlFor='username' srOnly>
				Username
			</Form.Label>
			<Form.Control
				className='mb-2 mr-sm-2'
				id='username'
				placeholder='Username'
				value={username}
				onChange={handleUsernameChange}
			/>
			<Form.Label htmlFor='password' srOnly>
				Username
			</Form.Label>
			<Form.Control
				className='mb-2 mr-sm-2'
				id='password'
				placeholder='Password'
				type='password'
				value={password}
				onChange={handlePasswordChange}
			/>
			<Button type="submit" className="mb-2">
    			Login
  			</Button>
		</Form>
	)
}

export default LoginForm